import React from 'react'
import * as fullHeroStyles from './fullhero.module.scss'
import '../styles/grid.scss'


const fullHero = ({title,description,image}) => {
    return(
      <div className={fullHeroStyles.hero}>
        <div className="container">
          <div className="row">     
            <div className="col-12 center">
              <img src={image} alt={title}/> 
            </div>
            <div className="col-12 center">
                <p data-sal="slide-up" data-sal-delay="200" >{description}</p>
            </div>
          </div>
        </div>  
      </div>
    )
  }

export default fullHero
    