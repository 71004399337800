import React from "react"
import * as heroStyles from './hero.module.scss'
import '../styles/grid.scss'
import iosButton from "../images/iosbutton.png"
import androidButton from "../images/androidbutton.png"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'


const Hero = ({title,description,image,trackLabel}) => {

    return(
      <div className={heroStyles.hero}>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className={heroStyles.text}>
                <h1 data-sal="slide-right" data-sal-duration="400" data-sal-delay="100">{title}</h1>
                <p data-sal="slide-right" data-sal-delay="300" dangerouslySetInnerHTML={{__html: description}}/>
                <div data-sal="slide-right" data-sal-delay="400" className={heroStyles.cta}>
                <div className={heroStyles.downloadbuttons}>
                  
                  <a onClick={e => {
                    //e.preventDefault()
                    trackCustomEvent({
                      category: "ios",
                      action: "click",
                      label: trackLabel
                    })
                  }} href="https://apps.apple.com/us/app/id1532621504" rel="noreferrer"  target="_blank"><img src={iosButton} alt="IOS indir"/></a>
                  <a onClick={e => {
                    //e.preventDefault()
                    trackCustomEvent({
                      category: "android",
                      action: "click",
                      label: trackLabel
                    })
                  }}  href="https://play.google.com/store/apps/details?id=ist.tio.cember" rel="noreferrer"  target="_blank"><img src={androidButton} alt="Android indir"/></a>
                </div>
                </div>
              </div>
            </div>
            <div className="col-6 center " >
              <div >
                <img  data-sal-duration="400" data-sal="slide-up" data-sal-delay="100" src={image} alt={title}/ > 
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }

export default Hero