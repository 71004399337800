import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import { RiArrowRightSLine } from 'react-icons/ri';
import Head from "../components/head"

import Layout from '../components/layout'
import Hero from '../components/hero'
import FullHero from '../components/fullhero'
import Money from '../components/money'
import heroImage from "../images/index_hero.png"
import borclanmak from "../images/borclanmak.svg"
import flyingLetter from "../images/flying_letter.svg"
import how1 from "../images/how_1.png"
import how2 from "../images/how_2.png"
import how3 from "../images/how_3.svg"
import how4 from "../images/how_4.svg"
import highlight1 from "../images/ne_icin_biriktiriyorsun.svg"
import highlight2 from "../images/hep_beraber_biriktirin.svg"
import highlight3 from "../images/hayallerini_finanse_et.svg"
import highlight4 from "../images/beklemede_kal.svg"
import hayalKiz from "../images/hayalin_ne.svg"
import birlikte from "../images/birlikte.png"
import lonca from "../images/lonca.png"
import digitalage from "../images/digitalage.png"
import webrazzi from "../images/webrazzi.png"
import entrecom from "../images/entrecom.png"
import boun from "../images/boun.png"
import takvim from "../images/takvim.png"

import '../styles/grid.scss'
import '../styles/index.scss'

const heroContent={
  title:"Arkadaşlarınla birlikte hayallerin için para biriktir!",
  description:"Şimdi Çember'i indir, <b>altın günü</b> modeliyle hedeflerine para biriktirerek ulaş.",
  image:heroImage,
  trackLabel:"hero"
}

const fullHeroContent={
  title:"",
  description:"Çember, bir sosyal birikim uygulamasıdır. Kullanıcılar finansmana ihtiyaç duydukları ortak bir konu veya miktar için çember oluşturarak birikim yapabilir. Hedeflenen miktar her ay ödeme alma sırası gelen kullanıcıya diğer çember katılımcıları tarafından gönderilir. ",
  image:borclanmak
}


const IndexPage = () => {
  const blogData = useStaticQuery(graphql`
        query{
          allMarkdownRemark(limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
            edges {
              node {
                frontmatter{
                  title
                  date(formatString: "DD.MM.YYYY")
                  hashtag
                  summary
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                fields{
                  slug
                }
              }
            }
          }
        }          
    `)


  return(
 
    <Layout >
      <Head title="Altın Günü Modeli ile Para Biriktirme Uygulaması" description="Çember, bir sosyal birikim uygulamasıdır. Kullanıcılar finansmana ihtiyaç duydukları ortak bir konu veya miktar için çember oluşturarak altın günü modeliyle birikim yapabilir. Her ay belirlenen miktar sırası gelen kullanıcıya gönderilir, her kullanıcı sırası geldiğinde ödeme alır." />
      <Hero title={heroContent.title}
      description={heroContent.description}
      image={heroContent.image} trackLabel={heroContent.trackLabel}/>  
      
      <Money></Money>

      <FullHero title={fullHeroContent.title}
      description={fullHeroContent.description}
      image={fullHeroContent.image}/>    
      <div id="takvim" className="row hidden-image">
        <img src={takvim} alt="Birikim Takvimi"/>
      </div>
      <div  className="row ctaBanner">
      
        <div className="row">
        
          <div  className="col-3">
            <span data-sal-delay="300" data-sal="fade" className="flying-text blue-highlight hidden-sm">
            Dijital takvimini
            </span>
            <span data-sal-delay="300" data-sal="fade" className="flying-text blue-highlight hidden-sm">
            PDF olarak al!
            </span>
            <span className="flying-left hidden-sm">
              <img data-sal-delay="400" data-sal="fade" src={flyingLetter} alt="İlk Sen Dene"/>
            </span>
            </div>
            <div  className="col-6">
              <div className="col-12 ">
                <h2 data-sal="slide-left">2022 Hedefler Takvimi’ni al, yeni yıl hedeflerini yaz!</h2>
               </div>
             
               <div data-sal="slide-left" data-sal-delay="100" className="col-12">   
               <form name="takvim" action="/calendar" method="post" netlify-honeypot="bot-field" data-netlify="true" >
                  <input type="hidden" name="form-name" value="takvim" />
                  <input required type="email" name="email" placeholder="E-posta adresin" />
                  <button type="submit" data-sal="slide-left" data-sal-delay="200">Gönder</button>
                </form>
               </div>
               
            </div>
            <div className="col-3">

            </div>
        </div>
      </div>
      <div className="row section-title">
        <h1 data-sal-duration="400" data-sal-delay="200"  data-sal="slide-down"  >Nasıl Biriktirirsin?</h1>
      </div>
      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1 data-sal-duration="400"  data-sal="slide-right" data-sal-delay="200">Çember Oluştur</h1>
                <p data-sal-duration="400"  data-sal="slide-right" data-sal-delay="500">Para biriktirmeye hemen başlamak için ilk Çember’ini oluştur ve bir isim ver. 
Aylık birikim hedefini, kaç kişi ile biriktirmek istediğini ve ödeme gününü seç. Birikim motivasyonunu da diğer Çember üyeleriyle paylaşabilirsin.</p>
                <img data-sal-duration="400"  data-sal="slide-right" data-sal-delay="700" className="highlight-image" src={highlight1} alt="Ne için biriktiriyorsun?" />
              </div>
            </div>
            <div className="col-6 center">
             
              <img data-sal-duration="600"  data-sal="zoom-in" data-sal-delay="200" src={how1} alt="Çember Oluştur"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>
      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
          <div className="col-6 center">
              <img data-sal-duration="600"  data-sal="zoom-out" data-sal-delay="100" src={how2} alt="Sevdiklerini Davet Et"/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1 data-sal-duration="400"  data-sal="slide-left" data-sal-delay="200">Sevdiklerini Davet Et</h1>
                <p data-sal-duration="400" data-sal="slide-left" data-sal-delay="500">Seninle aynı hayale sahip olan arkadaşlarını, aile bireylerini de davet et. İstediğin kadar fazla kişiyi davet edebilirsin. Çember ne kadar kalabalık olursa o kadar az ödersin. </p>
                <img data-sal-duration="400" data-sal="zoom-in" data-sal-delay="700" className="highlight-image" src={highlight2} alt="Hep beraber biriktirin" />
              </div>
            </div>
            
          </div>
        </div>  
      </div>
      </div>

      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1 data-sal-duration="400" data-sal="slide-right" data-sal-delay="200">Biriktirmeye Başla</h1>
                <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="500">Her ay ödemelerini yap, sıra sana geldiğinde toplu ödemeni al. Sevincini Çember üyeleriyle paylaşmayı unutma! </p>
                <img data-sal-duration="400" data-sal="zoom-in" data-sal-delay="700" className="highlight-image" src={highlight3} alt="Hayallerini finanse et!" />
              </div>
            </div>
            <div className="col-6 center">
             
              <img data-sal-duration="600" data-sal="slide-up" data-sal-delay="200"  src={how3} alt="Biriktirmeye Başla"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>

      <div className="row colored light-blue-bg">
       <div className="hero-container">
        <div className="container">
          <div className="row">
          <div className="col-6 center">
              <img data-sal="zoom-in" data-sal-delay="200"  data-sal-duration="600"  src={how4} alt="Çembere Katıl"/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1 data-sal="slide-left" data-sal-delay="100" data-sal-duration="600" >Çembere Katıl</h1>
                <p data-sal="slide-left" data-sal-delay="200"data-sal-duration="600" >Seninle aynı hayali paylaşan kişiler daha önce benzer hedefler koyarak Çember oluşturmuş olabilir. Daha önce oluşturulanları incele, sana en uygun olanına katıl! </p>
                <img data-sal="slide-left" data-sal-delay="300" data-sal-duration="600"  className="highlight-image" src={highlight4} alt="Çok yakında" />
              </div>
            </div>
            
          </div>
        </div>  
      </div>
      </div>

      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-7">
              <div className="hero-text">
                <h1 data-sal="slide-right" data-sal-duration="400">Senin hayalin ne?</h1>
                <div className="hayaller">
                    <button data-sal="zoom-in">Playstation 5  
                      <span   role="img" aria-label="emoji"  className="emoji">
                        🔫
                      </span>
                    </button>
                    <button data-sal="zoom-in" data-sal-delay="150">Araba 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🚙
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="650">Paris Tatili 
                      <span role="img" aria-label="emoji"  className="emoji">
                      💭
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="350">Airpods 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🎵
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="750">Master  
                      <span role="img" aria-label="emoji"  className="emoji">
                      🎓
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="250">Elektrikli Scooter  
                      <span role="img" aria-label="emoji"  className="emoji">
                      🛴 
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="300" >iPhone 
                      <span role="img" aria-label="emoji"  className="emoji">
                      📱 
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="550" >Alışveriş 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🛒 
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="850" >Dünya Turu 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🌎 
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="950" >Avokado Bahçesi
                      <span role="img" aria-label="emoji"  className="emoji">
                      🥑
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="1000" >Düğün
                      <span role="img" aria-label="emoji" className="emoji">
                      👰🤵
                      </span>
                    </button>

                    

                </div>
              </div>
            </div>
            <div className="col-5 center">
             
              <img data-sal="zoom-in" data-sal-delay="200" data-sal-duration="400" src={hayalKiz} alt="Senin hayalin ne?"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>

      <div className="row testimonials">
          <div className="row">
          <p data-sal="fade" data-sal-duration="400" className="center"> <span role="img" aria-label="heart">💙 💚 🖤</span> </p>
          </div>
          <div className="row">
            <div className="col-4">
                
                <p data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">“10 yıldır çeşitli altın günlerine katıldım, hep de organizasyon işi bana düştü! Her ay insanlara hatırlatmaktan sıkılmıştım, şimdi bu işi Çember'e bıraktık.“</p>
                <span data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">Ayla Karabağ</span>
            </div>
            <div className="col-4">
                
                <p data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">“Üniversiteden beri görüştüğüm bir arkadaş grubumla Çember oluşturup hem her ay görüşmek için bir bahane bulup hem para biriktirebiliyoruz. “</p>
                <span data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">Melisa Özdilek</span>
            </div>
            <div className="col-4">
             
                <p data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">“Oyun oynarken sürekli materyalleri geliştirmek şart, sadece bu amaçla benim gibi oyun oynayan arkadaşlarımla beraber para biriktirmek hoşuma gitti.  “</p>
                <span data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">Tunç Deha Uluç</span>
            </div>
          </div>
      </div>

      <div className="row faq">
        <div className="container">
          <div className="row section-title">
            <h1 data-sal="slide-down" data-sal-duration="400" data-sal-delay="200">Sık Sorulan Sorular 
              <span role="img" aria-label="post"> 
            🤔
              </span></h1>
          </div>
          <div className="row">
              <ul>
                <li>
                  <span data-sal="slide-right" data-sal-duration="400" data-sal-delay="200">Çember nedir?</span>
                  <p data-sal="slide-left" data-sal-duration="400" data-sal-delay="400">Çember, hayallerini finanse edebilmeni, borçlanmadan birikim ve harcama yapabilmeni sağlayan bir uygulamadır. Çevrendekilerle ortak bir hedef ve miktar belirleyerek her ay ödeme yaparsın ve her ay bir kişi biriken parayı alır.</p>
                </li>
                <li>
                  <span data-sal="slide-right" data-sal-duration="400" data-sal-delay="600">Uygulamayı nasıl indirebilirim?</span>
                  <p data-sal="slide-left" data-sal-duration="400" data-sal-delay="800">Çember’in mobil uygulamasını App Store veya Google Play üzerinden ücretsiz indirebilirsin.</p>
                </li>
                <li>
                  <span data-sal="slide-right" data-sal-duration="400" data-sal-delay="800">Nasıl kullanılır?</span>
                  <p data-sal="slide-left" data-sal-duration="400" data-sal-delay="1000">Çember App'in çalışma prensibi <b>altın günü birikim modeline</b> dayanmaktadır. 
                  Birlikte para biriktirmek istediğin arkadaşlarını oluşturduğun çembere davet edersin ve hedeflediğin kişi sayısına ulaştığında çemberiniz başlar. 
                  Ödeme alma sırası uygulama tarafından kura ile rastgele belirlenir. Kime, ne zaman, ne kadar ödeme yapacağını çember detayında görürsün. 
                  Ayrıca uygulama sana her ay ödeme ile ilgili hatırlatma gönderir. Birikim yaptığın arkadaşlarının kolayca ödeme gönderebilmesi için çembere hesap numaranı tanımlarsın. 
                  Uygulama kullanıcılardan herhangi bir ücret almaz veya hesabından para çekmez.</p>
                </li>
                <p>
                   <Link to="/help">Tüm Sorular</Link>
                </p>
              </ul>
          </div>
        </div>
      </div>

    

      

      <div className="row media light-gray-bg">
       <div className="hero-container">
        <div className="container">
          <div className="row center">
            <h3>Bizden Bahsedenler</h3>
          </div>
          <div className="row">
            <ul>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://loncagirisim.com/" target="_blank"><img alt="Bizden bahsedenler" src={lonca}/></a></li>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://digitalage.com.tr/lonca-girisimcilik-merkezinin-besinci-donemine-secilen-9-girisim/" target="_blank"><img alt="Bizden bahsedenler" src={digitalage}/></a></li>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://webrazzi.com/2020/11/30/kisilerin-ortak-bir-hedef-icin-birlikte-para-biriktirmelerini-saglayan-uygulama-cember/" target="_blank"><img alt="Bizden bahsedenler" src={webrazzi}/></a></li>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6673209389492633600/?actorCompanyId=53101797" target="_blank"><img alt="Bizden bahsedenler" src={entrecom}/></a></li>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://haberler.boun.edu.tr/tr/haber/altin-gununden-ilham-alan-projeye-buyuk-odul" target="_blank"><img alt="Bizden bahsedenler" src={boun}/></a></li>
            </ul>
          </div>
          <div className="row birlikte">
            <div className="col-6 center">
              <img data-sal="zoom-out" data-sal-duration="400" data-sal-delay="400" src={birlikte} alt="Birlikte Biriktiriyoruz"/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1 >Birlikte Biriktiriyoruz</h1>
                <p >
                  Hayallerini finanse edebilmeni, borçlanmadan birikim ve harcama yapabilmeni sağlamak için Çember uygulamasını geliştirdik. Yalnız değilsin! 
                </p>
                <p >
                  Hepimizin iyi bildiği para biriktirme yöntemini daha iyi bir deneyim haline getiriyoruz. Hayallerimizi finanse edebilmek için birlikte biriktiriyoruz.
                </p>
              </div>
            </div>
          </div>
        </div>  
      </div>
      </div>

      <div className="row featured-blog">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 data-sal="slide-down" data-sal-duration="400" data-sal-delay="200">Money, money, money <span role="img" aria-label="money">💸</span> </h3> <Link data-sal="slide-left" data-sal-duration="400" data-sal-delay="400" to="/blog/"> <RiArrowRightSLine size="24" style={{ 'color': "#F93666" }}/> Hepsini gör </Link>
            </div>
           
              {blogData.allMarkdownRemark.edges.map((edge)=>{
                let featuredImgFluid = edge.node.frontmatter.featuredImage.childImageSharp.fluid
                
                return(
                  <div className="col-4">
                    <Link  to={`/blog/${edge.node.fields.slug}`}>
                      <Img  fluid={featuredImgFluid} />
                      <h2 >{edge.node.frontmatter.title}</h2>
                      <p >{edge.node.frontmatter.summary}</p>
                      <span ><b>{edge.node.frontmatter.hashtag}</b></span>
                      <span >{edge.node.frontmatter.date}</span>
                    </Link>
                  </div>
                
                )
              })}
            
          </div>
        </div>
      </div>
      


    </Layout>
  )
}

export default IndexPage